<template>
  <v-app
    v-scroll="onScroll"
    :style="{background: $vuetify.theme.themes[theme].background}"
  >
    <v-app-bar
      app
      fixed
      height="82"
      elevation="1"
      :color="theme === 'dark' ? '' : 'white'"
      :hide-on-scroll="isMobile()"
    >
      <v-btn
        icon
        @click="toggleTheme"
      >
        <v-icon>
          {{ theme === 'dark' ? 'light_mode' : 'dark_mode' }}
        </v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      :mini-variant="isMobile()"
      app
      permanent
    >
      <v-list>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              がんばろう！
            </v-list-item-title>
            <v-list-item-subtitle>日本語を一生懸命勉強しよう！</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list
        nav
        dense
      >
        <v-list-item
          link
          v-for="item in menu_items"
          :key="item.id"
          :to="item.route"
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
      <v-fab-transition>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="scrolledToBottom"
              absolute
              bottom
              class="mb-10"
              color="primary"
              dark
              fab
              fixed
              right
              small
              v-bind="attrs"
              @click="backToTop"
              v-on="on"
            >
              <v-icon>arrow_upward</v-icon>
            </v-btn>
          </template>
          <span>Trở về đầu trang</span>
        </v-tooltip>
      </v-fab-transition>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data: () => ({
    menu_items: [
      {
        id: 'dashboard',
        text: 'Dashboard',
        icon: 'dashboard',
        route: '/'
      },
      {
        id: 'about',
        text: 'Random',
        icon: 'bx-dice-5',
        route: '/random'
      },
    ],
    scrolledToBottom: false,
  }),
  computed:{
    theme() {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  },
  methods: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem('theme', this.theme);
    },
    backToTop() {
      window.scrollTo(0, 0);
    },
    onScroll() {
      this.scrolledToBottom = window.scrollY > 100;
    }
    // toggleDrawer() {
    //   this.$vuetify.drawer.toggle()
    // }
  },
  mounted() {
    if (localStorage.getItem('theme') === 'dark') {
      this.$vuetify.theme.dark = true;
    }
    else {
      this.$vuetify.theme.dark = false;
    }
  }
};
</script>
