import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify)

const vuetify = new Vuetify({
  iconfont: 'bx',
  theme: {
    themes: {
      light: {
        background: '#fafafa', // Not automatically applied
      },
    },
  },
})

export default vuetify